<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { apiGetToken } from './api'
export default {
  name: 'App',
  data() {
    return {}
  }
}
</script>

<style lang="scss">
html,
body {
  height: 100%;
  background-color: #f7f7f7;
}
</style>
