import { get, post } from './http'

/**
 * 获取token
 * @param {code}
 * @type GET
 */
export const apiGetToken = p => get('/getToken', p)

/**
 * 发送验证码
 * @param {mobile}
 * @type POST
 */
export const apiPostSendSms = p => post('/sendSms', p)

/**
 * 手机号绑定
 * @param {bind}
 * @type POST
 */
 export const apiPostBind = p => post('/bind', p)

/**
 * 获取列表
 * @param {access_token, type} type 1待发货 2已发货
 * @type GET
 */
export const apiGetList = p => get('/order/list', p)

/**
 * 获取详情
 * @param {access_token, id}
 * @type GET
 */
export const apiGetDetail = p => get('/order/detail', p)

/**
 * 获取快递列表
 * @param {}
 * @type GET
 */
export const apiGetExpress = () => get('/getExpressList')

/**
 * 获取jssdk
 * @param {url}
 * @type GET
 */
export const apiGetJssdk = p => get('/getJssdk', p)

/**
 * 发货
 * @param {access_token, order_id, express, express_no}
 * @type POST
 */
export const apiPostSend = p => post('/order/send', p)
